.dotted-background {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    circle,
    var(--dotted-background-color) 1.2px,
    transparent 1px
  );
  background-size: 10px 10px;
  pointer-events: none;
  z-index: -1;
  opacity: 0.8;
}

.spaced-spray-paint {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: radial-gradient(
    circle at top left,
    transparent 10%,
    var(--brand-accent) 20%,
    transparent 30%
  );
  z-index: -1;
}

.spray-paint {
  position: absolute;
  height: 100vh;
  width: 100vw;
  bottom: 0;
  right: 0;
  background: radial-gradient(
    circle at bottom right,
    var(--brand-accent) 20%,
    transparent 30%
  );
  z-index: -1;
}

.cube-wave {
  position: absolute;
  bottom: 0;
  z-index: -1;
}
