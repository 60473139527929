/* Container Styles */
.productsView-page-container {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Top Controls Styling */
.top-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
  background-color: transparent;
  padding: 5px;
  border-radius: 8px;
}

.search-bar {
  color: var(--primary-color);
}

.search-icon {
  color: var(--brand-accent);
}

/* No Products Found Styling */
.no-products-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85vw;
  height: 70vh;
  border-radius: 8px;
}

.no-products-found img {
  width: 25%;
  height: auto;
}

.no-products-found p {
  font-size: 1.1rem;
  color: #000000;
  font-weight: 800;
  margin-top: 10px;
}

/* Products Container Grid Layout */
.products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 65vh;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  justify-items: center;
  width: 90vw;
  height: 100%;
}

/* Card Styling */
.product-card {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  opacity: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  animation: fadeIn 1s forwards;
  background: transparent;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.card-image {
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.card-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #0000007d;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  color: #ffffff;
  padding: 10px 10px;
  font-size: 1rem;
  font-weight: 450;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0;
  text-overflow: ellipsis;
}

.product-card a {
  text-decoration: none;
  color: inherit;
}

.product-card:hover {
  transform: translateY(-2px);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

/* Carousel Container Styling */
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}

/* Spinner Border Container Styling */
.spinner-border-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.spinner-border {
  color: #000000;
}

/* Pagination Container Styling */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  min-width: max-content;
  max-width: 80%;
  height: min-content;
  position: relative;
  padding: 10px;
  border-radius: 25px;
  overflow-x: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .products-grid {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 2vh;
  }

  .pagination-container {
    position: sticky;
    bottom: 5vh;
    z-index: 10;
    background: #00000056;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }
}

@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .no-products-found img {
    width: 50%;
  }

  .no-products-found p {
    font-size: 1rem;
  }
}

@media (max-width: 425px) {
  .products-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .product-card {
    width: 90%;
    height: auto;
  }
}
