.base-container {
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.notFound-page-container {
  text-align: center;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notFound-page-container h3 {
  font-size: 1.2rem;
  color: #000000;
  font-weight: 300;
}

.notFound-image {
  max-width: 25%;
  height: auto;
}

.back-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 5px 10px;
  background-color: #000000;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 0.8rem;
  gap: 5px;
}

.back-button:hover {
  background-color: #2d2d2d;
}
