.try-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 5vh;
}

.disclaimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80vw;
  text-align: center;
  background-color: var(--try-disclaimer);
  backdrop-filter: blur(2px);
  --webkit-backdrop-filter: blur(2px);
  color: var(--primary-color);
}

.disclaimer p {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-size: 0.8rem;
  padding: 10px;
  cursor: default;
}

.try-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.try-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 40vw;
  padding: 30px;
  height: 100%;
  background: var(--try-box-bg);
  backdrop-filter: blur(2px);
  --webkit-backdrop-filter: blur(2px);
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.try-box:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.08);
}

@media (max-width: 768px) {
  .try-box {
    width: 80vw;
  }
}

.try-box-text {
  display: flex;
  flex-direction: column;
}

.try-box h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.try-box h3 {
  font-size: 1rem;
  color: var(--secondary-color);
  margin-bottom: 20px;
}

.feature-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.feature-list li {
  font-size: 0.8rem;
  color: var(--secondary-color);
  margin-bottom: 5px;
  position: relative;
}

.try-box p {
  font-size: 0.9rem;
  color: var(--secondary-color);
  font-weight: 400;
}

.actions-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.try-box .join-button {
  background-color: var(--ternary-bg);
  color: var(--ternary-color);
  border: none;
  border-radius: 5px;
  padding: 14px 28px;
  width: 100%;
  height: 5vh;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.try-box .join-button:hover {
  background-color: var(--about-page-primary-button-hover);
}

.joinWaitlistResponse-placeholder {
  height: 1vh;
  margin-top: 10px;
}

.joinWaitlistResponse-placeholder p {
  font-size: 0.9rem;
  color: var(--secondary-color);
  margin-bottom: 0px;
}

.loading-circle {
  border: 2px solid transparent;
  border-top: 2px solid var(--ternary-color);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
